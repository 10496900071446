

$offwhite: #FAF0CC;
$black: #252729;
$grey: rgb(66, 66, 66);
$white: #fff;

$ipad: 1024px;
$iphoneplus: 560px;
$iphonex: 310px;
$ipadsmall: 768px;
$ipadsmall2: 800px;


* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

// when width hit 560 it will change
@mixin mobile {
  @media (min-width: #{$iphonex}) and  (max-width: #{$iphoneplus}) {
    @content;
  }
}

@mixin ipadsmall {
  @media (min-width: 560px) and  (max-width: 900px) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: 900px) and (max-width: 1280px ) {
    @content;
  }
}


// [data-scrollbar] {
//   width: 100%;
//   height: 1000px;
//   border: 3px solid #ccc;
//   // margin: 0 auto;
//   overflow: auto;
// }
.viewport {
  // margin: 0 0 0 0;
  width: 100vw;
  overflow-x: hidden;
  position: fixed;
}


html,
body {
    font-size: 16px;
  }
  @include ipad {
    font-size: 14px;
  }


 .overlay{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    @include mobile {
      position: absolute;
      width: 100%;
      height: 100vh;
    } 
}
.layer{
    background-color: $black;
}
.layer-1{
    flex: 1;
    
}
.layer-2{
    flex: 1;

}
.layer-3{
    flex: 1;

}

.outer{
  margin: 0;
  height: 124px;
  overflow: hidden;
  @include ipadsmall {
    margin: 0;
    height: 244px;
    overflow: hidden;
  }
  @include mobile {
    margin-top: -40px;
    height: 130px;
    overflow: hidden;

  }
}


.container2 {
  // visibility: hidden;
  // width: 1000px;
  // min-width: 768px;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 20px;
  @include mobile {
    // background-color: pink;
    padding: 0px 0px;

  }
    @include ipad {

      // background-color: blue;

    }
    @include ipadsmall {
    //  background-color: brown;

    }
}

.wrapperbrandbody{
      display:grid;
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      grid-template-columns:repeat(5, 1fr);
      grid-auto-rows:minmax(100px, auto);
      grid-auto-columns: minmax(100px, 100px);

      @include ipadsmall {
        grid-auto-rows:minmax(30px, auto);
      } 
      @include ipad {
        grid-auto-rows:minmax(60px, auto);
      }
      @include mobile {
        grid-auto-rows:minmax(20px, auto);

      }

       @include mobile {
        display:grid;
             grid-template-columns:repeat(2, 1fr);
                 .b3, .b4, .b7, .b8, .b9, .b10, .b11, .b12, .b13, .b14, .b15, .b16, .b19, .b20, .b21, .b22, .b23, .b24,  .b28, .b29, .b30,  .b33, .b34, .b37, .b39, .b40, .b42, .b44, .b47, .b48, .b49, .b50, .b53, .b54, .b55, .b56, .b57, .b58, .b59, .b60,  .b63, .b64, .b65, .b68, .b69, .b70, .b72, .b73, .b74, .b75, .b76, .b77, .b78, .b79, .b80, {display: none;}
             

       }
}







.react-player {
  position: absolute;
  top: 0;
  left: 0;

}


// name title
.team{
  padding: 0 10px;
  
  .title{
    font-family: 'avenirheavy';
    font-size: 0.9rem;
    letter-spacing: 0.2rem;
    @include ipadsmall {
      font-size: .75rem;
    }
    @include mobile {
      margin-top: 20px;
    }  
       
  }
  .name{
    letter-spacing: 0.1rem;
    font-size: 0.9rem;
    font-family: 'AvenirLTStd-Light';
    padding: 8px 0;
    margin-bottom: 20px;
    @include ipadsmall {
      font-size: .75rem;
    }
    @include mobile {
      line-height: 1.3rem;
    }  
       
  }
   
}

.imagesdolby {
  margin-top: -50px;
  @include mobile {

 }  
}

.imagestoyota {
  margin-top: 0px;
  padding-top: 50px;
  @include mobile {
     margin-top: -20px;
     padding-top: 20px;
  }  
}

.imagestoyota2 {
  margin-top: 0px;
  padding-top: 15px;
  @include mobile {
    margin-top: -20px;
    //  padding-top: 20px;
  }  
}

.imagesasics {
  margin-top: 0px;
  padding-top: 50px;
  @include mobile {
     margin-bottom: -50px;
  }  
}


//footer
.footer {
  padding: 0px 10px 0 ;
  // @include ipadsmall {
  //   padding: 50px 10px ;
  //   }  
  

  .horizontalline {
    width: 100%;
    border-bottom: 1px solid;
    padding: 0px;
    color: $offwhite;
  }
  .nested3{
    display:grid;
    // grid-template-columns:repeat(3, 1fr);
    grid-template-columns: 50% 1px 50%;
    
  }
  .verticalline{
    display:grid;
    grid-template-columns:repeat(2, 1fr);
    grid-gap:0em;
    border-left: $offwhite 1px solid;
    padding:0em;
    height: 300px;
    @include ipad {
      height: 250px;    }
    @include ipadsmall {
      height: 200px;
    }
    @include mobile {
      height: 120px;
    }
  }
  .back{
    // align-items: end;
    align-self: center; //vertical placement
    justify-self: center; //horizontal placement
    font-size: 10rem;
    letter-spacing: 1rem;
    cursor: pointer;
    transition: color .5s;    
    &:hover {
      color: $white;
    }    
    @include ipad {
      font-size: 8.5rem;
    }    
    @include ipadsmall {
      font-size: 6rem;
    }
    @include mobile {
      font-size: 2.5rem;
    }
    p{
      font-size: 1rem;
      letter-spacing: .2rem;
      margin-top: -20px;
      text-align: left; 
      @include mobile {
        font-size: 0.6rem;
        margin-top: -10px;
      }
    }
  }
  .next{
    // align-items: end;
    align-self: center; //vertical placement
    justify-self: center; //horizontal placement
    font-size: 10rem;
    letter-spacing: 1rem;
    cursor: pointer;
    transition: color .5s;    
    &:hover {
      color: $white;
    }
    @include ipad {
      font-size: 8.5rem;
    }
    @include ipadsmall {
      font-size: 6rem;
    }
    @include mobile {
      font-size: 2.5rem;
      margin-right: -22px;

    }
    p{
      font-size: 1rem;
      letter-spacing: .2rem;
      margin-top: -20px;
      margin-right: 12px;
      text-align: right; 
      @include mobile {
        font-size: 0.6rem;
        margin-top: -10px;
      }
    }
  } 
.socialfeed{
    display:flex;
    justify-content:space-around;
    height: 80px;
    
    
    a{
      display: inline-block;
    }
    @include mobile{
      display:flex;
      justify-content:space-evenly;
      a{
        display: inline;
      }
    }

.item1{
  flex:1;
  display: flex;
  letter-spacing: 2px;
  justify-content: space-around;
  align-self: center; //vertical placement
  margin: 0 150px;
  @include mobile {
    letter-spacing: 1px;
    margin: 0 50px;
 }  
}

.item2{
   align-self: center; //vertical placement
   letter-spacing: 2px;
  @include ipadsmall {
     font-size: .9rem;
  }  
  @include mobile {
    font-size: .9rem;
    align-self: center; //vertical placement
    letter-spacing: 1px;
 }  
}

// .item1{
//   flex:1;
//   display: flex;
//   justify-content: center;
//   align-self: center; //vertical placement
//   transform: translateX(25px);/*D element Width[if needed]*/
//   letter-spacing: .2rem;
//    a{
//        margin: 0 40px;
//      }
//      @include ipadsmall {
//       font-size: .75rem;
//       transform: translateX(15px);
//     }   
//     @include mobile {
//       align-self: center; //vertical placement
//       font-size: .7rem;
//       transform: translateX(0px);
//       display: flex;
//       justify-content: left;
//       // transform: translateX(-35px);
//     }   
//     @media (max-width: 375px) {
//       transform: translateX(0px);
//        margin: 0 -30px;
//     }  
// }
// .item2{
//   align-self: center; //vertical placement
//   letter-spacing: .2rem;
//   @include ipadsmall {
//     font-size: .75rem;
//   }      
//   // @include mobile {
//   //   font-size: .7rem;
//   //   justify-content: right;
//   //   margin-top: -15px; 
//   //   margin-left: 20px;
//   //   // transform: translateX(-20px);

//   // }    
}

.cool-link::after{
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: $offwhite;
  transition: width .3s;
  @include mobile {
    height: 0px;

  }
}
.cool-link:hover::after{
  width: 100%;
  transition: width .3s;

}

}




.wrapperbrandbody > div{
      /* background-color: rgba(139, 55, 55, 0.705); */
      // padding:.2em;
      // padding-left: .1em;
      border-left:.5px solid rgba(250, 240, 204, 0.2);
      @include ipadsmall {

        border-left:.5px solid rgba(250, 240, 204, 0);
      }

}
.wrapperbrandbody > div:nth-child(5n+0){
      border-right :.5px solid $offwhite ;
      border-right:.5px solid rgba(250, 240, 204, 0.2);
      @include ipadsmall {

        border-right:.5px solid rgba(250, 240, 204, 0);
      }


}

@include mobile {

  .wrapperbrandbody > div:nth-child(2n+0){
    // border-right :.5px solid $offwhite ;
    border-right:.5px solid rgba(250, 240, 204, 0.1);
    border-left:.5px solid rgba(250, 240, 204, 0.1);
  }
  .wrapperbrandbody > div:nth-child(1n+0){
    border-left:.5px solid rgba(250, 240, 204, 0.1);
  }
  .wrapperbrandbody > div:nth-child(6){
    border-right: .1px solid rgba(24, 23, 20, 0.404);
  }
  .wrapperbrandbody > div:nth-child(5){
    // border-right:.5px solid rgba(6, 134, 151, 0.1);
    // border-left:.5px solid rgba(250, 240, 204, 0.1)
  }

} 


.imagehero {
  z-index: 100;
  position: relative;

  font-size: 0px;
}

.images {

  overflow: hidden;
  position: relative;
  z-index: 10;

  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    background-color: $black;
    // z-index: 100;
  }
}


.first {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
    
  }

}
.second {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }

}

.third {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.fourth {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.fifth {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}
.sixth {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }

}
.seventh {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }

}
.eight {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.nine {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.ten {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {
      border: .1px solid rgba(250, 240, 204, .0);
      
    }
  }

}

.eleven {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.twelve {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.thirteen {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.fourteen {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.fifthteen {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.sixteen {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.seventeen {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.eighteen {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.nineteen {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 99.9%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}


.twenty {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.twentyone {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}



a {
  font-weight: 400;
  position: relative;
  z-index: 100;
  text-decoration: none;
  color: $offwhite;
  cursor: pointer;
  font-family: 'voyageregular';
  transition: color .5s;
  &:hover {
    color: $white;
  }
}




.cool-link::after{
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: $white;
  transition: width .3s;
}
.cool-link:hover::after{
  width: 100%;
  transition: width .3s;
}





.headlinecategory {
  // position: absolute;
  font-size: 6rem;
  letter-spacing: 0.35rem;
  text-align: right;
  @include mobile {
    margin-top: -5px;
    font-size: 3.5rem;
    line-height: 3.8rem;

  } 

}


.headlinebrand {
  // position: absolute;
  font-family : 'voyageregular';
  font-weight: 400;
  decoration : none;
  // margin-left: 525px;
  font-size: 4rem;
  letter-spacing: 0.5rem;
  text-align: right;
  @include mobile {
    margin-top: 60px;
    font-size: 3.5rem;
    line-height: 4rem;

  } 
}





.headlinecategory2 {
  // position: absolute;
  font-size: 6rem;
  margin-top: 80px;
  letter-spacing: 0.35rem;
  line-height: 6rem;
  text-align: right;
  @include ipadsmall {
    //  background-color: brown;

    }
  @include mobile {
    margin-bottom: 0px;
    margin-top: 50px;
    margin-right: 10px;
    font-size: 4rem;
    line-height: 4rem;
    padding-bottom: 15px;

  } 
}

.headlinecategoryVideo {
  // position: absolute;
  font-size: 6rem;
  margin-top: 80px;
  letter-spacing: 0.35rem;
  text-align: right;
  @include mobile {
    display: none;

  } 
}



.toyotaheadline {
  // position: absolute;
  font-size: 6rem;
  margin-top: 80px;
  margin-bottom : -70px;
  letter-spacing: 0.35rem;
  line-height: 6rem;
  text-align: right;
  @include ipad {
    margin-bottom : -20px;
  }
  @include ipadsmall {
    margin-top: 30px;
    margin-bottom : -20px;
    line-height: 5rem;
    //  background-color: brown;

    }
  @include mobile {
    margin-bottom: 0px;
    margin-top: 50px;
    margin-right: 10px;
    font-size: 3.5rem;
    line-height: 3.8rem;
    padding-bottom: 15px;

  } 
}




.headlinecategory3 {
  // position: absolute;
  font-size: 6rem;
  margin-top: 80px;
  padding-left: 10px;
  letter-spacing: 0.35rem;
  text-align: left;

}

.headlinebrandtext, .headlinebrandtext1, .headlinebrandtext2  {
  // position: absolute;
  font-family: 'AvenirLTStd-Light';
  font-size: 1rem;
  font-weight: 400;
  
  line-height: 1.75rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  @include ipadsmall {
    font-size: 1rem;
    text-align: left;
    padding-bottom: 20px;
  }   
  @include mobile {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1rem;
    line-height: 2rem;

  }  

}





.b1{
  @include mobile {
    grid-column: 1;
    grid-row: 1;
  } 
}
.b2{
  @include mobile {
    height: 100px;
    grid-column: 2;
    grid-row: 1;
  } 
}

// .b4{
//   @include mobile {
//     grid-column: 2;
//     grid-row: 2;
//   } 
// }
.b5{
  @include mobile {
    grid-column: 2;
    grid-row: 2;
    border-right: 50px solid rgba(250, 240, 204, 1);
  } 
}

.b5 > div{
  // border-left: 50px solid rgba(250, 240, 204, 1);
}

.b6{
  grid-column: 1/6;
  grid-row: 2;
  @include mobile {
    grid-column: 1/3;
    grid-row: 2;

  } 
}


.b7{
  grid-column: 2;
  grid-row: 2;
  
}
.b8{
  grid-column: 3;
  grid-row: 2;
}
.b9{
  grid-column: 4;
  grid-row: 2;
}
.b10{
  grid-column: 5;
  grid-row: 2;
}
.b11{
  grid-column: 1;
  grid-row: 3;
}
.b12{
  grid-column: 2;
  grid-row: 3;
  @include mobile {
    grid-column: 2; 
  }
}

.b13 {
  grid-column: 3;
  grid-row: 3;
  @include mobile {
    grid-row: 4;
    
  }
}
.b14 {
  grid-column: 4;
  grid-row: 3;

}
.b15 {
  grid-column: 5;
  grid-row: 3;

}

.b16 {
  grid-column: 1/3;
  grid-row: 4;
  @include mobile {
    grid-column: 1;
  } 

}
.b17 {
  grid-column: 2;
  grid-row: 4;

}
.b18 {
  grid-column: 3/6;
  grid-row: 4;
  @include mobile {
    grid-column: 1/3;
  }
}
.b19 {
  grid-column: 4;
  grid-row: 4;
  @include mobile {
    grid-column: 1;
    
  }
}
.b20 {
  grid-column: 5;
  grid-row: 4;
  @include mobile {
    grid-column: 2;
    grid-row: 4;
  }
}

.b21 {
  grid-column: 1;
  grid-row: 5;

}
.b22 {
  grid-column: 2;
  grid-row: 5;
}
.b23 {
  grid-column: 3;
  grid-row: 5;
}
.b24 {
  grid-column: 4;
  grid-row: 5;
}
.b25 {
  grid-column: 5;
  grid-row: 5;
  @include mobile{
     display: none;
    grid-column: 1;
    grid-row: 3;
  }
}

.b26 {
  grid-column: 1/4;
  grid-row: 6;
  @include ipadsmall {

    grid-column: 1/6;
    grid-row: 6;
    }  
  @include mobile {
    grid-column: 1/3;
    grid-row: 3;
  }
}
.b27 {
  grid-column: 2;
  grid-row: 6;
  @include ipadsmall {

    grid-column: 2;
    grid-row: 6;
    }  
  @include mobile {
    grid-column: 2;
    grid-row: 3;
  }
}
.b28 {
  grid-column: 3;
  grid-row: 6;
  @include ipadsmall {

    grid-column: 3;
    grid-row: 6;
    }  
  @include mobile {
    grid-column: 2;
  }
}
.b29{
  grid-column: 4;
  grid-row: 6;
  @include ipadsmall {

    grid-column: 4;
    grid-row: 6;
    }  

}
.b30{
  grid-column: 5;
  grid-row: 6;
  @include ipadsmall {

    grid-column: 5;
    grid-row: 6;
    }  
  @include mobile {
    grid-column: 2;
    grid-row: 5;
  }  
}
.b31 {
  grid-column: 1/6;
  grid-row: 7;
  @include ipadsmall {
    grid-column: 1/6;
    grid-row: 7;

   }
  @include mobile {
    grid-column: 1/3;
    grid-row: 5;
    
  }  
}

.jordanb31 {
  grid-column: 1/6;
  grid-row: 7;
  @include mobile {
    grid-column: 1/3;
    grid-row: 4;
    
  }  
}



.b32 {
  grid-column: 2;
  grid-row: 7;
  @include ipadsmall {
    grid-column: 2;
    grid-row: 7;

   }
  @include mobile {
    grid-column: 2;
    grid-row: 5;
    
  }  
 }
.b33 {
  grid-column: 3;
  grid-row: 7;
  @include ipadsmall {
    grid-column: 3;
    grid-row: 7;
   }

}

.b34 {
  grid-column: 4;
  grid-row: 7;
  @include ipadsmall {
    grid-column: 4;
    grid-row: 7;
   }

}
.b35 {
  grid-column: 5;
  grid-row: 7;
  @include ipadsmall {
    grid-column: 3;
    grid-row: 7;

   }
  @include mobile {
    grid-column: 1;
    grid-row: 7;
    display: none;


  }
}
.b36 {
  grid-column: 1/3;
  grid-row: 8;
  @include ipadsmall {
    padding: 20px 0;
    grid-column: 2/6;
    grid-row: 9;
    }    
  @include mobile {
    grid-column: 1/3;
    grid-row: 7;
    display: none;

  }
}


.videob36 {
  grid-column: 1/3;
  grid-row: 8;
  @include ipadsmall {
    padding: 20px 0;
    grid-column: 2/6;
    grid-row: 10;
    }    
  @include mobile {
    grid-column: 1/3;
    grid-row: 7;

  }
}




.b37 {
  grid-column: 2;
  grid-row: 8;
  @include ipadsmall {
    grid-column: 1;
    grid-row: 8;
    }  
  @include mobile {
    grid-column: 1;
  }
}
.b38 {
  grid-column: 3/6;
  grid-row: 8;
  @include ipadsmall {
    grid-column: 2/6;
    grid-row: 8;
    }    
  @include mobile {
    grid-column: 1/3;
    grid-row: 6;
    // display: none;


  }
}
.b39 {
  grid-column: 4;
  grid-row: 8;
  @include ipadsmall {
    grid-column: 4;
    grid-row: 8;
    }   
  @include mobile {
    grid-column: 1;
   
  }
}
.b40 {
  grid-column: 5;
  grid-row: 8;
  @include ipadsmall {
    grid-column: 5;
    grid-row: 9;
    }    
  @include mobile {
    grid-column: 2;
    
  }
}



.b41 {
  grid-column: 1/6;
  grid-row: 9;
  // padding-bottom: 100px;
  // background-color: red;
  @include mobile {
    grid-column: 1/3;
    grid-row: 6;
    // padding-bottom: 50px;

   
  }
}

.jordanb41 {
  grid-column: 1/6;
  grid-row: 9;
  padding : 30px 0;
  // padding-bottom: 100px;
  // background-color: red;
  @include mobile {
    grid-column: 1/3;
    grid-row: 5;
    padding-bottom: 30px;

   
  }
}

.space41{
  // top: 50px;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  //background-color: red;

}

.video640{
  // top: 50px;
  position: relative;
  padding-top: 73.12%; /* Player ratio: 100 / (640 / 468) */
  //background-color: red;

}



.b42 {
  grid-column: 2;
  grid-row: 9;
  @include mobile {
    display: none;
    grid-column: 2;
    grid-row: 5;
   
  }
}




.b43{
  grid-column: 3;
  grid-row: 9;
  @include ipadsmall {
    display: none;
    } 
    @include mobile {
      display: none;
      grid-column: 1/2;
      grid-row: 7;
     
    }
}
.b44{
  grid-column: 4;
  grid-row: 9;
  @include ipadsmall {
    display: none;
    } 
}
.b45{
  grid-column: 5;
  grid-row: 9;
  @include ipadsmall {
    display: none;
    } 
    @include mobile {
      display: none;
      grid-column: 1;
      grid-row: 8;
    }    
}

.jordanb45{
  grid-column: 5;
  grid-row: 9;
  @include ipadsmall {
    display: none;
    } 
    @include mobile {
      grid-column: 1;
      grid-row: 8;
    }    
}

.b46 {
  grid-column: 1/6;
  grid-row: 10;
  
  @include mobile {
    
    grid-column: 1/3;
    grid-row: 8;
    
  }
}

.b46toyota {
  grid-column: 1/6;
  grid-row: 8;
  @include mobile {
    grid-column: 1/3;
    grid-row: 8;
  }
}

.b47 {
  grid-column: 2;
  grid-row: 10;
  @include mobile {
    grid-column: 1;
  }
} 
.b48 {
  grid-column: 3;
  grid-row: 10;
  @include mobile {
    grid-column: 2;
  }
}
.b49 {
  grid-column: 4;
  grid-row: 10;
  @include mobile {
    grid-column: 1;
  }
}
.b50 {
  grid-column: 5;
  grid-row: 10;
  @include mobile {
    grid-column: 2;
  }
}

.b51 {
  grid-column: 1/5;
  grid-row: 11;
  @include mobile {
    grid-column: 1/3;
    grid-row: 9;
  }
}


.b52 {
  grid-column: 2;
  grid-row: 11;
  @include mobile {
    grid-column: 2;
    grid-row: 9;
  }
}
.b53 {
  grid-column: 3;
  grid-row: 11;
}
.b54 {
  grid-column: 4;
  grid-row: 11;
}
.b55 {
  grid-column: 5;
  grid-row: 11;
}

.b56 {
  grid-column: 1/4;
  grid-row: 12;
  @include mobile {
    grid-column: 2;
  }
}



.b57 {
  grid-column: 2;
  grid-row: 12;
  @include mobile {
    grid-column: 1;
  }
}
.b58 {
  grid-column: 3;
  grid-row: 12;
  @include mobile {
    grid-column: 2;
  }
}
.b59 {
  grid-column: 4;
  grid-row: 12;
  @include mobile {
    grid-column: 1;
  }
}
.b60 {
  grid-column: 5;
  grid-row: 12;
  @include mobile {
    grid-column: 2;
  }

}

.b61 {
  grid-column: 1/6;
  grid-row: 13;
  // bottom: 25px;
  // background-color: red;
  @include mobile {
    grid-column: 1/3;
    grid-row: 13;
  }
}

.space61{
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin-right: 1px;
  bottom: 50px;
  // background-color: red;

}

.b62 {
  grid-column: 2;
  grid-row: 13;
  @include mobile {
    grid-column: 2;
    grid-row: 13;
  }
}
.b63 {
  grid-column: 3;
  grid-row: 13;
}
.b64 {
  grid-column: 4;
  grid-row: 13;
}
.b65 {
  grid-column: 5;
  grid-row: 13;
}

.b66{
  // position: relative;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  grid-column: 1/6;
  grid-row: 14;
  @include mobile {
    grid-column: 1/3;
    grid-row: 14;
  }
}

.space66{
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  bottom: 25px;
  margin-right: 1px;
}


.jordanb66{
  // position: relative;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  grid-column: 1/6;
  grid-row: 11;
  @include mobile {
    grid-column: 1/3;
    grid-row: 9;
  }
}
.video2{
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin-right: 1px;
  margin-bottom: 50px;
}


.jordanb67 {
  grid-column: 2;
  grid-row: 11;
  @include mobile {
    grid-column: 2;
    grid-row: 9;
  }
}
.jordanb68 {
  grid-column: 3;
  grid-row: 11;
  @include mobile {
    grid-column: 2;
    display: none;

  }
}
.jordanb69 {
  grid-column: 4;
  grid-row: 11;
  @include mobile {
    grid-column: 1;
    display: none;

  }
}
.jordanb70 {
  grid-column: 5;
  grid-row: 11;
  @include mobile {
    grid-column: 2;
    display: none;

  }

}



.b67 {
  grid-column: 2;
  grid-row: 14;
  @include mobile {
    grid-column: 2;
    grid-row: 14;
  }
}
.b68 {
  grid-column: 3;
  grid-row: 14;
  @include mobile {
    grid-column: 2;
  }
}
.b69 {
  grid-column: 4;
  grid-row: 14;
  @include mobile {
    grid-column: 1;
  }
}
.b70 {
  grid-column: 5;
  grid-row: 14;
  @include mobile {
    grid-column: 2;
  }

}







.b71{
  // top: 25px;
  // position: relative;
  // padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  grid-column: 1/6;
  grid-row: 15;
  @include mobile {
    border: .1px solid rgba(250, 240, 204, .1);
    grid-column: 1/3;
    grid-row: 15;
  }
}

.space71{
  // top: 25px;
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  margin-bottom: 30px;
  margin-right: 1px;
  @include mobile {
    margin-left: -1px;
  }
}
.b72{
  grid-column: 2;
  grid-row: 15;
}
.b73{
  grid-column: 3;
  grid-row: 15;
}
.b74{
  grid-column: 4;
  grid-row: 15;
}
.b75{
  grid-column: 5;
  grid-row: 15;
}



//@include



.jfirst {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
    
  }

}
.jsecond {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }

}

.jthird {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.jfourth {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}

.jfifth {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}
.jsixth {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }

}
.jseventh {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }

}
.jeight {

  overflow: hidden;
  position: relative;
  z-index: 10;
  margin-bottom: 50px;
  &:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    border: .1px solid rgba(250, 240, 204, .1);
    border-left: .1px solid rgba(250, 240, 204, 0);
    background-color: $black;
    @include ipadsmall {

      border: .1px solid rgba(250, 240, 204, 0);
      
    }
  }
}