@font-face {
  font-family: 'voyagebold';
  src: url('voyage-bold-webfont.woff2') format('woff2'),
       url('voyage-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'voyageregular';
  src: url('voyage-regular-webfont.woff2') format('woff2'),
       url('voyage-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'AvenirLTStd-Light';
  src: url('AvenirLTStd-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'avenirheavy';
  src: url('aeh-webfont.woff2') format('woff2'),
       url('aeh-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


$offwhite: #FAF0CC;
$black: #252729;
$grey: rgb(66, 66, 66);
$white: #fff;

$ipad: 1024px;
$iphoneplus: 560px;
$iphonex: 300px;
$ipadsmall: 768px;
$ipadsmall2: 800px;


* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

// when width hit 560 it will change
@mixin mobile {
  @media (min-width: #{$iphonex}) and  (max-width: #{$iphoneplus}) {
    @content;
  }
}

@mixin ipadsmall {
  @media (min-width: 560px) and  (max-width: 900px) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: 900px) and (max-width: 1280px ) {
    @content;
  }
}


// [data-scrollbar] {
//   width: 100%;
//   height: 1000px;
//   border: 3px solid #ccc;
//   // margin: 0 auto;
//   overflow: auto;
// }
.viewport {
  // margin: 0 0 0 0;
  width: 100vw;
  overflow-x: hidden;
  position: fixed;
}


FADE Transitions
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}



html,
body {
  padding: 0;
  margin: 0;
  background-color: $black;
  font-family: 'voyageregular';
  color: $offwhite;
  @include mobile {
    font-size: 14px;

  }
  @include ipad {
    font-size: 14px;

  }
}



.overlay2{
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
       z-index: 199;
      display: flex;
      @include mobile {
        position: absolute;
        width: 150%;
        height: 100vh;
      } 
  }
  .layer2{
      background-color: $black;
  }
  .layer-12{
      flex: 1;
      
  }
  .layer-22{
      flex: 1;
  
  }
  .layer-32{
      flex: 1;
  
  }






.container {
  // width: 1280px;
  // min-width: 1280px;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0px 20px;
  @include mobile {
    // background-color: pink;
    padding: 0px 10px;

  }
    @include ipad {

      // background-color: blue;

    }
    @include ipadsmall {
    //  background-color: brown;

    }
}
 
//HEADER 

header {
  // width: 1280px;
  // min-width: 1230px;
  max-width: 1230px;
  margin: 0 auto;


  @include mobile {
    padding: 0px 10px;
 
  }
  .inner-header {
    // max-width: 1230px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    z-index: 300;

    .logo a {
      z-index: 300;

      font-family: 'voyageregular';
      font-weight: 400;
      font-size: 0.8rem;
      letter-spacing: 0.2rem;
      text-decoration: none;
      color: grey;
      right: -10px;
      transition: color .5s;
      &:hover {
        color: $white;
      }
      @include mobile {
        right: -5px;
        font-size: 0.75rem;
        } 
      @include ipad {
        right: -25px;
      }
      @include ipadsmall{
        right: -25px;
      }
    }
    .middle a{
      z-index: 300;

      right: -185px;
      font-family: 'voyageregular';
      font-weight: 400;
      font-size: 0.8rem;
      letter-spacing: 0.2rem;
      text-decoration: none;
      color: grey;
      transition: color .5s;
      &:hover {
        color: $white;
      }
      @include mobile {
        right: -2px;
        font-size: 0.75rem;

        } 
        @include ipad {
          right: -170px;
        
          }
      @include ipadsmall{
        right: -10px;
      }

    }
     .menu {
      z-index: 300;
      
      button {
        padding: 0px 0px 0px;
        margin-right: 5px;
        // right: 215px;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        color: grey;
        letter-spacing: 0.2rem;
        font-family: 'voyageregular';
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.3rem;
        text-align: left;
        transition: color .5s;
        &:hover {
          color: $white;
        }
        @include mobile {
          margin-right: 2px;
          font-size: 0.75rem;
          line-height: 1.3rem;
          text-align: right;
          } 
        @include ipad {
            margin-right: 22px;
          }          
        @include ipadsmall{
          margin-right: 22px;
      }          
      }

    }
  }

}


   // Define hamburger menu
    .hamburger-menu {
      display: none;
      z-index: 9;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 200;
      .menu-secondary-background-color{
       background-color: $grey;
       top:0;
       bottom: 0;
       left: 0;
       right: 0;
       position: fixed;
       height: 100%;
       width: 100%;
       z-index: 100;
      }
     .menu-layer {
       position: relative;
       background : $offwhite;
       height: 100%;
       overflow: hidden;
       z-index: 150;
 
       .wrapper{
         position: relative;
         
 
           
         .info{
            
               font-family: 'voyagebold';
               color: $grey;
               text-decoration: none;
               font-size: 9.5rem;
               font-weight: 500;
               letter-spacing: 0.1rem;
               line-height: 0.9;
              //  margin: 8px auto;

              position: absolute;
              margin-top: -300px;
              right: 45px;

               @include mobile {
                font-size: 4.5rem;
                left: 5px;
                margin-top: -140px;

              }
               @include ipadsmall{
                right: 20px;
                left: 15px;
              }


            }
            
         



         .brands{
           display: flex;
           width: 95%;
           justify-content: space-between;
           font-family: 'voyageregular';
           font-size: 2rem;
           font-weight: 400;
           letter-spacing: 0.15rem;
           line-height: 1.5rem;
           margin-top: 500px;
           margin-left: 35px;

           cursor: pointer;
           a {
             color: $grey;
             text-decoration: none;
             transition: .3s ease-in-out;
             &:hover {
               color: $white;
             }
           }
           
           @include mobile {
            flex-direction: column;
            font-size: 1.5rem;
            margin-left: 10px;
            line-height: 1rem;
            margin-top: 250px;
          }
           @include ipadsmall{
            display: flex;
            width: 95%;
            margin-left: 25px;
            right: 550px;
            font-size: 1.5rem;
              }
              

         }
        }
     }
   }

   


   // body content starts here
   //global elements
.welcometext{
    font-family: 'voyagebold';
    font-size: 16rem;
    letter-spacing: .6rem;
    line-height: 14rem;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 50px;
    margin-left: 10px;
    @include ipad {
      font-size: 11rem;
      letter-spacing: .2rem;
      line-height: 10rem;
    }
    @include ipadsmall {
      font-size: 7rem;
      letter-spacing: .2rem;
      line-height: 6rem;
 
     }    
    @include mobile {
      margin-top: -40px;
      font-size: 4rem;
      letter-spacing: .3rem;
      line-height: 4rem;
      text-align: left;
    
    }  
  }

.links{
  text-transform: uppercase;
  font-size: 9rem;
  line-height: 8rem;
  letter-spacing: .6rem;
  margin-left: 10px;
  @include ipad {
    font-size: 7rem;
    letter-spacing: .2rem;
    line-height: 6.5rem;
  }
  @include ipadsmall {
    font-size: 5rem;
    letter-spacing: .2rem;
    line-height: 5rem;
   }    
   @include mobile {
    
    letter-spacing: .2rem;
    font-size: 3rem;
    line-height: 3.2rem;
 
  }     
  
}


.work {
  margin-top: 10px;
  font-family: 'voyagebold';
  font-size: 16rem;
  letter-spacing: .6rem;
  margin-left: 10px;

  @include ipad {
    font-size: 12rem; 
    letter-spacing: 1rem;
  }
  @include ipadsmall {
    font-size: 10rem; 
    letter-spacing: 1rem;

   }    
  @include mobile {
    font-size: 4rem;
    letter-spacing: .2rem;
  
  }    
}





.projects{
  font-family: 'voyagebold';
  text-transform: uppercase;

  font-size: 6rem;
  line-height: 7rem;
  margin-left: 10px;
  @include mobile {
    font-size: 3rem;
    line-height: 3.5rem;
  } 
   @include ipad {


  }
   @include ipadsmall {
    font-size: 5rem;
    line-height: 5rem;

  } 
}

.Catergory {
  text-transform: uppercase;
}


.awards {
  margin-top: -100px;
  font-family: 'voyagebold';
  font-size: 16rem;
  letter-spacing: 1rem;
  margin-left: 10px;

  @include ipad {
    font-size: 12rem; 
    letter-spacing: 1rem;
  }
  @include ipadsmall {
    font-size: 7.5rem; 
    letter-spacing: 0.5rem;

   }    
  @include mobile {
    margin-left: 0px;
    font-size: 4rem;
    letter-spacing: .2rem;
  
  }    
}


.list {
  margin-left: 10px;
}

.awardwon {
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  line-height: 2.3rem;
  @include ipad {
    font-size: 1.2rem; 
    letter-spacing: 0.2rem;
  }
  @include ipadsmall {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  @include mobile {
    font-size: 1rem;
    line-height: 1.5rem;
    }
}


.contact {
  font-family: 'voyagebold';
  font-size: 15rem;
  letter-spacing: 1rem;
  margin-left: 5px;

  @include ipad {
    font-size: 10.5rem; 
    letter-spacing: 1rem;
  }
  @include ipadsmall {
    font-size: 6rem; 
    letter-spacing: 0.5rem;

   }    
  @include mobile {
    font-size: 3.7rem;
    letter-spacing: .2rem;
  
  }    

}

.contactdetails{

  margin-left: 10px;
  text-align: left;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  line-height: 2.3rem;
  @include ipadsmall {
    line-height: 2rem;
   }
   @include mobile {
    font-size: 1rem;
    letter-spacing: .2rem;
    line-height: 1.4rem;
  
  }    
}



//footer
.footermain {
  padding: 0px 0px 0 ;
  margin-left: 10px;
  margin-right: 10px;
  @include mobile{
    margin-left: 0px;
    margin-right: 0px;
  }
 
  .horizontalline {
    width: 100%;
    border-bottom: 1px solid;
    padding: 0px;
    color: $offwhite;
  }



.socialfeed{
    display:flex;
    justify-content:center;
    height: 80px;
    a{
      display: inline-block;
    }
    @include mobile{
      height: 80px;
    }

}
.item1{
  flex:1;
  display: flex;
  justify-content: center;
  align-self: center; //vertical placement
  transform: translateX(25px);/*D element Width[if needed]*/
  letter-spacing: .2rem;
   a{
       margin: 0 40px;
     }
     @include ipadsmall {
      font-size: .75rem;
    }   
    @include mobile {
      font-size: .75rem;
      justify-content: left;
      align-self: center; //vertical placement
      transform: translateX(-35px);

    }   
}
.item2{
  align-self: center; //vertical placement
  letter-spacing: .2rem;
  @include ipadsmall {
    font-size: .75rem;
  }      
  @include mobile {
    font-size: .75rem;
    justify-content: right;
    // transform: translateX(-20px);

  }    
}

.cool-link::after{
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: $offwhite;
  transition: width .3s;
}
.cool-link:hover::after{
  width: 100%;
  transition: width .3s;
}

}




.wrapperbody{
display:grid;
position: relative;
margin-left: 10px;
margin-right: 10px;
grid-template-columns:repeat(5, 1fr);
grid-auto-rows:minmax(100px, auto);
grid-auto-columns: minmax(100px, 100px);

@include ipadsmall {
  grid-auto-rows:minmax(30px, auto);
} 
@include ipad {
  grid-auto-rows:minmax(60px, auto);
}
@include mobile{
  margin-left: 0px;
  margin-right: 0px;
}

}

.wrapperbody > div{
      /* background-color: rgba(139, 55, 55, 0.705); */
      // padding:.2em;
      padding-left: .1em;
      border-left:.5px solid rgba(250, 240, 204, 0.2)
}
.wrapperbody > div:nth-child(5n+0){
      // border-right :.5px solid $offwhite ;
      border-right:.5px solid rgba(250, 240, 204, 0.2)
}



// .cover{
//   height: 720px;
//   margin-top: -100px;
//   margin-left: -10px;
//   margin-right: -10px;
//   // background: url('https://cl.ly/3k1F152x261C/download/wallpaper3.jpg');
//   background: url('../src/images/mlk.png');
//   background-size: cover;
//   background-position: center;
// }

.box1{

      grid-column: 1;
      grid-row: 1;
     

    }
    .box2{

    }
    .box6{
      grid-column: 1/6;
      grid-row: 2;

    }
    .box7{
      grid-column: 2;
      grid-row: 2;
 

    }
    .box8{
      grid-column: 3;
      grid-row: 2;
      
    }
    .box9{
      grid-column: 4;
      grid-row: 2;
    }
    .box10{
      grid-column: 5;
      grid-row: 2;
    }
    .box11{
      grid-column: 1;
      grid-row: 3;
        
    }
    .box12{
      grid-column: 2;
      grid-row: 3;
    }
    .box13{
      grid-column: 3;
      grid-row: 3;
    }
    .box14{
      grid-column: 4/6;
      grid-row: 3;  
 
          
    }
    .box15{
      grid-column: 5;
      grid-row: 3;  


    }
    .box16{
      grid-column: 1/5;
      grid-row: 4;

 
    }
    .box17{
      grid-column: 2;
      grid-row: 4;

    }
    .box18{
      grid-column: 3;
      grid-row: 4;
 
    }
    .box19{
      grid-column: 4;
      grid-row: 4;
    }
    .box20{
      grid-column: 5;
      grid-row: 4;
    }
    .box21{
      grid-column: 1/6;
      grid-row: 5;
 
    }
    .box22{
      grid-column: 2;
      grid-row: 5;

    }


    .box23{
      grid-column: 3;
      grid-row: 5;

    }
    .box24{
      grid-column: 4;
      grid-row: 5;

      
    }
    .box25{
      grid-column: 5;
      grid-row: 5;

    }

    .box26{
      height: 140px;
      grid-column: 1;
      grid-row: 6;

    }
    .box27{
      grid-column: 2;
      grid-row: 6;

    }
    .box28{
      grid-column: 3;
      grid-row: 6;

    }
    .box29{
      grid-column: 4;
      grid-row: 6;
    }
    .box30{
      grid-column: 5;
      grid-row: 6;
    }
    .box31{
      grid-column: 1/6;
      grid-row: 7;

  
    }
    .box32{
      grid-column: 2;
      grid-row: 7;

    }
    .box33{
      grid-column: 3;
      grid-row: 7;

    }
    .box34{
      grid-column: 4;
      grid-row: 7;
    
    }
    .box35{
      grid-column: 5;
      grid-row: 7;
    }
    .box36{
      grid-column: 1/6;
      grid-row: 8;

    }
    .box37{
      grid-column: 2;
      grid-row: 8;
  
    }
    .box38{
      grid-column: 3;
      grid-row: 8;
 
    }
    .box39{
      grid-column: 4;
      grid-row: 8;
  
      
    }
    .box40{
      grid-column: 5;
      grid-row: 8;
    }
    .box41{
      grid-column: 1;
      grid-row: 9;

    }
    .box42{
      grid-column: 2;
      grid-row: 9;
 
    }
    .box43{
      grid-column: 3;
      grid-row: 9;

    }
    .box44{
      grid-column: 4;
      grid-row: 9;
    }
    .box45{
      grid-column: 5;
      grid-row: 9;
    }
    .box46{
      grid-column: 1/6;
      grid-row: 10;

    }

    .box47{
      grid-column: 2;
      grid-row: 10;

    }
    .box48{
      grid-column: 3;
      grid-row: 10;

    }
    .box49{
      grid-column: 4;
      grid-row: 10;

    }
    .box50{
      grid-column: 5;
      grid-row: 10;

    }
    .box51{
      grid-column: 1/6;
      grid-row: 11;
      
    }
    .box52{
      grid-column: 2;
      grid-row: 11;
    }
    .box53{
      grid-column: 3;
      grid-row: 11;
    }
    .box54{
      grid-column: 4;
      grid-row: 11;
    }
    .box55{
      grid-column: 5;
      grid-row: 11;


    }
    .box56{
      grid-column: 1;
      grid-row: 12;

    }
    .box57{
      grid-column: 2;
      grid-row: 12;

    }
    .box58{
      grid-column: 3;
      grid-row: 12;

    }
    .box59{
      grid-column: 4;
      grid-row: 12;
    }
    .box60{
      grid-column: 5;
      grid-row: 12;
    }
    .box61{
      grid-column: 1;
      grid-row: 13;
    }
    .box62{
      grid-column: 2;
      grid-row: 13;
    }
    .box63{
      grid-column: 3;
      grid-row: 13;
    }
    .box64{
      grid-column: 4;
      grid-row: 13;
    }
    .box65{
      grid-column: 5;
      grid-row: 13;
    }


    .box66{
      grid-column: 1;
      grid-row: 14;
   
    }
    .box67{
      grid-column: 2;
      grid-row: 14;

    }
    .box68{
      grid-column: 3;
      grid-row: 14;
    }
    .box69{
      grid-column: 4;
      grid-row: 14;
    }
    .box70{
      grid-column: 5;
      grid-row: 14;
    }
    .box71{
      grid-column: 1;
      grid-row: 15;
    }
    .box72{
      grid-column: 2;
      grid-row: 15;
    }
    .box73{
      grid-column: 3;
      grid-row: 15;
    }
    .box74{
      grid-column: 4;
      grid-row: 15;
    }
    .box75{
      grid-column: 5;
      grid-row: 15;
    }
    .box76{
      grid-column: 1;
      grid-row: 16;
    }
    .box77{
      grid-column: 2;
      grid-row: 16;

    }
    .box78{
      grid-column: 3;
      grid-row: 16;

    }
    
    .box79{
      grid-column: 4;
      grid-row: 16;

    }
    .box80{
      grid-column: 5;
      grid-row: 16;
      border: 0;
    }



.cursor{
  position: absolute;
  top: 10px;
  left: 10px;
  width: 32px;
  height: 32px;
  background: red;
  border-radius: 100%;
  transform: translate (-50%, -50%);
  transition: all 0.1s ease-in-out;
  transition-property: width, height, border;
  will-change: width, height, transdorm, border;
  pointer-events: none;
  z-index: 999;
}
.cursor::before{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
}